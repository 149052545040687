import { IonPage } from "@ionic/react";

import { DeprecatedGlobalAppV1Paths } from "../App/paths";
import { AppBarHeader, BackButtonLink, PageWithHeader } from "../lib";
import { useDefinedWorker } from "../Worker/useDefinedWorker";
import { useCareerAgentConfig } from "./useCareerAgentConfig";

export function CareerAgentFormPage() {
  const { userId: workerId } = useDefinedWorker();
  const { screenName, url } = useCareerAgentConfig();
  const fullUrl = url + workerId;

  return (
    <IonPage>
      <PageWithHeader
        appBarHeader={
          <AppBarHeader
            title={screenName}
            leftCta={<BackButtonLink defaultBackTo={DeprecatedGlobalAppV1Paths.ACCOUNT} />}
          />
        }
      >
        {/* eslint-disable-next-line react/iframe-missing-sandbox */}
        <iframe
          src={fullUrl}
          title="Career Agent Form"
          width="100%"
          height="100%"
          style={{ border: "none" }}
          allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
        />
      </PageWithHeader>
    </IonPage>
  );
}
