import { Browser } from "@capacitor/browser";
import { Image, Text } from "@clipboard-health/ui-react";
import { InAppBrowser } from "@ionic-native/in-app-browser";

import { DeprecatedGlobalAppV1Paths } from "../App/paths";
import { APP_V2_USER_EVENTS, logEvent } from "../lib/analytics";
import { LinkCard } from "../lib/Links/LinkCard";
import { useDefinedWorker } from "../Worker/useDefinedWorker";
import mobilePhoneImage from "./assets/images/mobilePhone.png";
import { useCareerAgentConfig } from "./useCareerAgentConfig";

export function CareerAgentBanner() {
  const { userId: workerId } = useDefinedWorker();
  const { title, description, url, method } = useCareerAgentConfig();

  async function handleClick(): Promise<void> {
    logEvent(APP_V2_USER_EVENTS.CAREER_AGENT_PAGE_VIEWED, { workerId });
    if (method === "native-browser") {
      await Browser.open({ url, toolbarColor: "#001529", presentationStyle: "fullscreen" });
    } else if (method === "in-app-browser") {
      InAppBrowser.create(url, "_blank", {
        allowInlineMediaPlayback: "yes",
        location: "no",
        hidenavigationbuttons: "yes",
        toolbarposition: "bottom",
        closebuttoncaption: "Done",
        closebuttoncolor: "#ffffff",
        toolbarcolor: "#001529",
        toolbartranslucent: "no",
        footer: "yes",
        footercolor: "#001529",
        zoom: "no",
        useWideViewPort: "no",
      });
    }
  }

  return (
    <LinkCard
      to={{
        pathname:
          method === "iframe"
            ? DeprecatedGlobalAppV1Paths.CAREER_AGENT
            : DeprecatedGlobalAppV1Paths.ACCOUNT,
      }}
      title={
        <Text bold variant="body2">
          {title}
        </Text>
      }
      subtitle={<Text variant="caption">{description}</Text>}
      variant="info"
      leadingIcon={<Image src={mobilePhoneImage} alt="Phone" width={42} height={42} />}
      onClick={() => {
        void handleClick();
      }}
    />
  );
}
